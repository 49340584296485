@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@300;400;500;600;700&display=swap');

* {
    @apply font-poppins;
}

h1, h2, h3, h4, h4, h5, h6 {
    @apply font-comfortaa;
}

#root {
    min-height: 100vh;
}

.standard-button:active {
    transform: scale(.95) !important;
}

.standard-button {
    @apply transition-all duration-150;
}

.standard-secondary-button {
    @apply transition-all duration-150;
}

.standard-secondary-button:active {
    transform: scale(.95) !important;
}

.standard-button:hover {
    background-color: #035a7c !important;
}

.standard-secondary-button:hover {
    background-color: #97643b !important;
}
