@media screen and (min-width: 768px) {
    .detail-image {
        -webkit-mask: linear-gradient(180deg, #000 0% 75%, #0000);
        mask: linear-gradient(180deg, #000 0% 75%, #0000);
    }
}




   