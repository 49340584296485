.filter-chips {
    flex-wrap: wrap;
}

.filter-chips::after {
    content: "";
    flex: auto;
}

@media screen and (max-width: 768px) {
    .filter-chips::after {
        content: unset;
    }
}
