
.contact h3{
    margin-top: 2%;
    font-size: large;
    margin-left: 2%;
    padding-bottom: 5px;
    font-weight: 400;
}

.contact h1{
    font-size: xx-large;
    font-weight: 700;
}

.contact option{
    padding: 2%;
    border-bottom: 1px solid lightgray;
    font-size: medium;
    display: flex;
}


.background{
    background-image: url('/public/assets/images/logo/SVG/Flugzeug_farbe.svg');
    background-position: center;
    background-size: 170%;
    padding: 1px;
}


.contact input {
    @apply w-8/12 bg-[#fff] outline-[#046C95] border text-base mb-2 ml-3 px-2 py-2.5 rounded-2xl border-solid border-[#ccc];
}

.contact textarea{
    @apply w-8/12 bg-[#fff] outline-[#046C95] border text-base mb-2 ml-3 px-2 py-2.5 rounded-2xl border-solid border-[#ccc];
}

.contact select{
    @apply w-8/12 bg-[#fff] outline-[#046C95] border text-base mb-2 ml-3 px-1 py-1 rounded-2xl border-solid border-[#ccc] overflow-auto;
}

.contact button {
    @apply bg-[#016c95] text-[white] text-center text-[15px] mt-2.5 px-2.5 py-[5px] ml-3 rounded-[20px] border-0 transition-all duration-300;
}


@media (max-width: 768px) {
    .contact option {
        font-size: small;
    }
}

@media (max-width: 550px) {
    .contact input {
        width: 90%;
    }

    .contact textarea {
        width: 90%;
    }

    .contact select {
        width: 90%;
    }
}