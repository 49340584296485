.slide-content {
    -webkit-mask: linear-gradient(90deg, #0000, #000 5% 95%, #0000);
    scroll-behavior: smooth;
    scrollbar-width: thin;
    scrollbar-color: #979797 #ffffff00;
    position: relative;
}

.result-nav-button.active {
    background-color: #878787;
}

/* Chrome, Edge, and Safari */
.slide-content::-webkit-scrollbar {
    width: 12px;
}

.slide-content::-webkit-scrollbar-track {
    background: #ffffff00;
}

.slide-content::-webkit-scrollbar-thumb {
    background-color: #d3d3d3;
    border-radius: 10px;
}

.button-left {
    position: absolute !important;
    top: 50% !important;
    left: 0 !important;
    transform: translate(-50%, -50%) !important;
}

.button-right {
    position: absolute !important;
    top: 50% !important;
    right: 0 !important;
    transform: translate(50%, -50%) !important;
}

@media screen and (max-width: 768px) {
    .slide-content {
        -webkit-mask: none;
    }
}