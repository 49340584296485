.navbar a:hover {
    border-bottom: solid 3px #eee;

}

.navbar a.active {
    border-bottom: solid 3px #eee;
}

.dropdown-menu{
    position: absolute;
    background-color: rgb(4 108 149 / var(--tw-bg-opacity));
    color: #fff;
    top: 124px;
    right: 0;
    width: 55%;
    padding: 0 15px 0;
    z-index: 1;
    text-align: center;
    border-radius: 0 0 0 35px;

}

.dropdown-menu-scroll{
    position: absolute;
    background-color: rgb(4 108 149 / var(--tw-bg-opacity));
    color: #fff;
    right: 0;
    padding: 0 15px 0;
    width: 55%;
    z-index: 1;
    text-align: center;
    border-radius: 0 0 0 35px;
    top: 55px;
}

.dropdown-menu ul li{
    padding: 25px;
    border-top: 1px solid white;
    text-align: center;
}

.dropdown-menu-scroll ul li{
    padding: 25px;
    border-top: 1px solid white;
}


.dropdown-menu.active{
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
    transition: .3s ease;
}

.dropdown-menu.inactive{
    opacity: 0;
    visibility: hidden;
    transform: translateY(-40px);
    transition: .3s ease;
}

.dropdown-menu a{
    font-family: Comfortaa, sans-serif;
    font-size: large;
}

.dropdown-menu-scroll.active{
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
    transition: .3s ease;
}

.dropdown-menu-scroll.inactive{
    opacity: 0;
    visibility: hidden;
    transform: translateY(-40px);
    transition: .3s ease;
}

.dropdown-menu-scroll a{
    font-family: Comfortaa, sans-serif;
    font-size: large;
}

@media (max-width: 312px) {
    .dropdown-menu-scroll {
        height: 255px;
    }
}

@media (max-width: 320px) {
    .dropdown-menu {
        height: 255px;
    }
}