.aboutus h2 {
  @apply text-xl font-bold;
}

.aboutus .gruender section {
  @apply md:h-2/5 md:w-2/5;
}
.aboutus .gruender section img {
  @apply border rounded-[20px] w-full;
}

.aboutus .gruender button {
  @apply bg-[#016c95] text-[white] text-center text-[15px] mt-2.5 px-2.5 py-[5px] rounded-[20px] border-0 transition-all duration-300;
}

.aboutus .gruender .kurzcv {
  @apply opacity-0 transition-all duration-300;
}

.aboutus .gruender .kurzcv.active {
  @apply opacity-100;
}

.whyandratings section h2 {
  @apply text-lg pb-2.5;
}

.aboutus .leftblock {
  @apply md:w-3/5 text-justify;
}
.aboutus .rightblock {
  @apply md:w-2/5;
}