.booking label {
    @apply block mb-2;
}

.booking input {
    @apply w-full bg-[#EFF0F7] outline-[#A97142] border text-base mb-2 px-2 py-2.5 rounded-2xl border-solid border-[#ccc];
}

.booking input:focus {
    outline: #A97142 2px solid;
}